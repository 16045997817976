import { FC } from 'react';

import { CustomNextImage as Image } from '@crehana/next';
import { Col, Container, IconButton, Row } from '@crehana/ui';

import {
  FormWrapper,
  GradientWrapper,
  StyledWrapper,
} from './B2CLayout.styles';
import { CrehanaLogos } from './components';
import { B2CLayoutProps } from './types';

const B2CLayout: FC<React.PropsWithChildren<B2CLayoutProps>> = ({
  onBack,
  children,
}) => (
  <StyledWrapper className="relative text-white w-full h-full">
    <div className="hidden md:block">
      <Image
        src={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-6fbbfb46-e3bc-4c41-a6f0-7c5ea407fb14-bg-desktop.png?auto=format'
        }
        alt="Crehana"
        blurDataURL={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-6fbbfb46-e3bc-4c41-a6f0-7c5ea407fb14-bg-desktop.png?auto=format&blur=200&px=24'
        }
        placeholder="blur"
        layout="fill"
        objectFit="cover"
      />
    </div>
    <div className="hidden sm:block md:hidden">
      <Image
        src={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-dbb74745-3e7f-42fd-aa2f-36517972650c-bg-tablet.png?auto=format'
        }
        alt="Crehana"
        blurDataURL={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-dbb74745-3e7f-42fd-aa2f-36517972650c-bg-tablet.png?auto=format&blur=200&px=24'
        }
        placeholder="blur"
        layout="fill"
        objectFit="cover"
      />
    </div>
    <GradientWrapper className="absolute w-full h-full" />
    <div className="relative flex flex-wrap items-center justify-center w-full min-h-screen py-20">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div className="mb-24 flex justify-center md:items-center h-full">
              <CrehanaLogos />
            </div>
          </Col>
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={5}
            offset={{
              sm: 2,
              md: 0,
              lg: 0,
            }}
          >
            <div className="relative">
              {onBack && (
                <IconButton
                  className="absolute left-0 -ml-72 hidden sm:block"
                  isWhite
                  arrow="left"
                  onClick={onBack}
                />
              )}
              <FormWrapper className="rounded-10 px-20 py-24 lg:px-48 lg:py-48">
                {children}
              </FormWrapper>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </StyledWrapper>
);

export default B2CLayout;
