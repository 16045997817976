import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { clsx } from 'clsx';
import { BehaviorSubject, from } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';

import { linkFormatter, useTranslation } from '@crehana/i18n';
import { Loader, TextField } from '@crehana/ui';

import { REGEX_EMAIL } from '@/shared/crehana-jsx/Shared/regex';
import AUTH_PAGES_USER_B2B_VERIFICATION_QUERY from '@/views/Auth/graphql/AuthPagesUserB2bVerificationQuery.v4.b2b.graphql';
import {
  AuthPagesUserB2bVerificationQuery,
  AuthPagesUserB2bVerificationQueryVariables,
} from '@/views/Auth/graphql/types/AuthPagesUserB2bVerificationQuery';
import routes from '@/views/Auth/routes';

import { EmailInputProps } from './types';

const initialState = {
  loading: false,
  networkStatus: 7,
  data: {
    userB2bVerification: null,
  },
};

// TODO: talk with the code owner to fix this code so we can enable this rule again
// eslint-disable-next-line react/display-name
const EmailInput = forwardRef<HTMLInputElement, EmailInputProps>(
  (
    {
      className,
      errorText,
      orgSlug,
      isLoaderWhite = false,
      hideB2CMsg = false,
      hideB2BActivateMsg = false,
      hideB2BLoginMsg = false,
      defaultValue = '',
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const _emailBehavior = useRef(new BehaviorSubject(defaultValue));
    const [{ loading, data }, setQueryResult] =
      useState<ApolloQueryResult<AuthPagesUserB2bVerificationQuery>>(
        initialState,
      );
    const apolloClient = useApolloClient();
    const errorCode = data.userB2bVerification?.code || 0;
    const isB2B =
      (!hideB2BActivateMsg && errorCode === 8051) ||
      (!hideB2BLoginMsg && errorCode === 8052);
    const isB2C = !hideB2CMsg && !errorText && errorCode === 8050;
    const currentEmail = _emailBehavior.current.getValue();
    const messages = useMemo(() => {
      const emailQueryParam = `?email_user=${currentEmail}`;
      const formatter = (text: string) =>
        linkFormatter({
          text,
          className: clsx(
            'font-legals',
            isLoaderWhite ? 'text-secondary-main' : 'text-primary-main',
          ),
        });

      return {
        8050: t('B2C_EMAIL_MESSAGE', {
          interpolation: {
            url: routes.b2bLoginPath + emailQueryParam,
          },
          formatter,
        }),
        8051: t('B2B_EMAIL_ACTIVATE_MESSAGE', {
          interpolation: {
            url: orgSlug
              ? routes.b2bOrgActivePath(orgSlug) + emailQueryParam
              : routes.b2bActivePath + emailQueryParam,
          },
          formatter,
        }),
        8052: t('B2B_EMAIL_REGISTERED_MESSAGE', {
          interpolation: {
            url: routes.b2bLoginPath + emailQueryParam,
          },
          formatter,
        }),
      };
    }, [currentEmail, isLoaderWhite, orgSlug, t]);

    useEffect(() => {
      const emailSub = _emailBehavior.current
        .asObservable()
        .pipe(
          filter(email => {
            setQueryResult(initialState);
            return (
              (email.length >= 6 || email.length <= 50) &&
              REGEX_EMAIL.test(email)
            );
          }),
          debounceTime(500),
          switchMap(email => {
            setQueryResult({ ...initialState, loading: true });
            return from(
              apolloClient.query<
                AuthPagesUserB2bVerificationQuery,
                AuthPagesUserB2bVerificationQueryVariables
              >({
                query: AUTH_PAGES_USER_B2B_VERIFICATION_QUERY,
                context: { clientName: 'v4.b2b' },
                fetchPolicy: 'no-cache',
                notifyOnNetworkStatusChange: true,
                variables: {
                  email,
                },
              }),
            );
          }),
        )
        .subscribe(setQueryResult);

      return () => {
        emailSub.unsubscribe();
      };
    }, [apolloClient]);

    return (
      <div className={className}>
        <TextField
          wrapperClassName="rounded-10"
          {...rest}
          ref={ref}
          endAdornment={
            loading ? <Loader isWhite={isLoaderWhite} size={16} /> : undefined
          }
          errorText={errorText}
          onChange={e => _emailBehavior.current.next(e.target.value)}
          error={isB2B}
          check={isB2C}
        />
        {!errorText && (isB2B || isB2C) && (
          <span
            className={clsx('block font-caption mt-8', {
              'text-red-main': isB2B,
              'text-secondary-main': isB2C && !isLoaderWhite,
              'text-white': isB2C && isLoaderWhite,
            })}
            role="alert"
          >
            {messages[errorCode]}
          </span>
        )}
      </div>
    );
  },
);

export default EmailInput;
