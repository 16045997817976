import { FC } from 'react';

import CrehanaLogo from '@crehana/icons/CrehanaLogo';
import CrehanaPremium from '@crehana/icons/CrehanaPremium';
import { Container } from '@crehana/ui';
import { white } from '@crehana/ui/dist/styles/colors';
import { useMe } from '@crehana/web/ReactContexts/AuthContext';

interface CustomNavbarProps {
  isRenovationFlow?: boolean;
  isClient?: boolean;
  isBgTransparent?: boolean;
}

const CustomNavbar: FC<React.PropsWithChildren<CustomNavbarProps>> = ({
  isRenovationFlow,
  isClient,
  isBgTransparent,
}) => {
  const { me } = useMe();

  const homeUrl = me?.organization?.slug
    ? `/org/${me.organization.slug}/learn/`
    : '/';

  const renderLoginLogo = () => {
    if (isRenovationFlow && !isClient) {
      return null;
    }

    return isRenovationFlow ? (
      <CrehanaPremium size={36} color={white} />
    ) : (
      <CrehanaLogo size={24} color={white} />
    );
  };

  return (
    <div
      className={`${
        isBgTransparent ? 'bg-transparent' : 'bg-base-main'
      } w-full`}
    >
      <Container>
        <p className="inline-block">
          <a
            href={homeUrl}
            className="py-32 flex items-center justify-center sm:justify-start"
          >
            {renderLoginLogo()}
          </a>
        </p>
      </Container>
    </div>
  );
};

export default CustomNavbar;
