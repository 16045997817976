import CrehanaForBusiness from '@crehana/icons/logos/CrehanaForBusiness';

const CrehanaLogos = () => (
  <>
    <CrehanaForBusiness className="sm:hidden" size={32} />
    <CrehanaForBusiness className="hidden sm:block lg:hidden" size={28} />
    <CrehanaForBusiness className="hidden lg:block" size={34} />
  </>
);

export default CrehanaLogos;
