import { FC } from 'react';

import { clsx } from 'clsx';

import { DividerProps } from './types';

const Divider: FC<React.PropsWithChildren<DividerProps>> = ({
  className,
  lineClassName = 'bg-gray-main',
}) => {
  const line = <span className={clsx(lineClassName, 'flex-grow w-112 h-1')} />;

  return (
    <div className={clsx(className, 'w-full flex items-center')}>
      {line}
      <span className="flex-grow-0 font-body4 px-20">O</span>
      {line}
    </div>
  );
};

export default Divider;
