import styled from 'styled-components';

export const StyledWrapper = styled.div`
  background: linear-gradient(180deg, #0f1124 0%, #1d2559 170%);
  ${props => props.theme.breakpoints.up('sm')} {
    background: radial-gradient(
        circle farthest-corner at 49% 99%,
        #40376e 0%,
        rgba(255, 255, 255, 0) 18.13%
      ),
      linear-gradient(180deg, #40376e 0%, rgba(255, 255, 255, 0) 67%),
      linear-gradient(180deg, #0f1124 0%, #1d2559 170%);
  }
`;

export const GradientWrapper = styled.div`
  background: radial-gradient(
      200% 100% at 30% 120%,
      #3417aa 0%,
      rgba(255, 255, 255, 0) 40%
    ),
    radial-gradient(
      ellipse at -40% 20%,
      rgba(242, 22, 115, 0.25) 0%,
      rgba(255, 255, 255, 0) 40%
    ),
    radial-gradient(
      ellipse at 120% 0%,
      #0bd4c1 0%,
      rgba(11, 212, 193, 0.21) 10%,
      rgba(255, 255, 255, 0) 43%
    );

  ${props => props.theme.breakpoints.up('md')} {
    background: radial-gradient(
        ellipse at 86% 4%,
        rgba(11, 212, 193, 0.5) 0%,
        rgba(0, 0, 0, 0.1) 20.52%,
        rgba(0, 0, 0, 0.1) 37%,
        rgba(7, 14, 39, 0) 50%
      ),
      radial-gradient(
        ellipse at 9% 7%,
        rgba(242, 22, 115, 0.25) 0%,
        rgba(0, 0, 0, 0.1) 30%,
        rgba(255, 255, 255, 0) 54%
      ),
      radial-gradient(
        circle farthest-corner at 5% 100%,
        #3417aa 0%,
        rgba(0, 0, 0, 0.2) 51%,
        rgba(255, 255, 255, 0) 66%
      );
  }
`;

export const FormWrapper = styled.div`
  background: rgba(141, 141, 157, 0.08);
  backdrop-filter: blur(40px);
`;
