import { FC } from 'react';

import { clsx } from 'clsx';

import { useTranslation } from '@crehana/i18n';

import SocialButtons from '@/shared/components/AuthForm/components/SocialButtons';

import { B2BLoginSocialButtonsProps } from './types';

const B2BLoginSocialButtons: FC<
  React.PropsWithChildren<B2BLoginSocialButtonsProps>
> = ({ className, nextUrl, label, children }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(className, 'flex flex-col items-center sm:flex-row')}>
      <span className="tw-font-subtitle3 mb-12 sm:mr-24 sm:mb-0">
        {label || t('CONTINUE_WITH', 'Continúa con:')}
      </span>
      <div>
        {children ?? (
          <SocialButtons
            authType="signIn"
            variant="minimal-3"
            hideFacebookLink
            hideFacebookButton
            nextUrl={nextUrl}
            showMicrosoftButton
          />
        )}
      </div>
    </div>
  );
};

export default B2BLoginSocialButtons;
