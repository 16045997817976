import { FC } from 'react';

import { PrimaryButton } from '@crehana/ui';

import { FormSubmitButtonProps } from './types';

const FormSubmitButton: FC<React.PropsWithChildren<FormSubmitButtonProps>> = ({
  className,
  ...rest
}) => (
  <div className={className}>
    <PrimaryButton
      {...rest}
      type="submit"
      className="sm:hidden"
      size="s"
      full
    />
    <PrimaryButton
      {...rest}
      type="submit"
      className="hidden sm:inline-flex"
      size="l"
      full
    />
  </div>
);

export default FormSubmitButton;
