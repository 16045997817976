import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle<{ primaryColor?: string }>`
  body {
    --color-primary-dark: ${props => props.primaryColor};
    --color-primary-main: ${props => props.primaryColor};
    --color-primary-light: ${props => props.primaryColor};
    --color-primary-lighter: ${props => props.primaryColor};
  }
`;

export const BarWrapper = styled.div`
  height: 65vh;
  ${props => props.theme.breakpoints.up('sm')} {
    height: 60vh;
  }
  ${props => props.theme.breakpoints.up('md')} {
    height: 100%;
  }
`;
