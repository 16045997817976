import { FC, useEffect, useState } from 'react';

import CrehanaLogoIso from '@crehana/icons/CrehanaLogoIso';
import { CustomNextImage as Image } from '@crehana/next';
import { IconButton } from '@crehana/ui';
import Skeleton from '@crehana/ui/dist/Skeleton';
import { getImgxUrl } from '@crehana/utils';

import PoweredBy from '@/shared/components/PoweredBy';
import { verifyURLImage } from '@/shared/utils/imgixLoader';

import { GlobalStyles } from './B2BOrgLayout.styles';
import { B2BOrgLayoutProps } from './types';

const B2BOrgLayout: FC<React.PropsWithChildren<B2BOrgLayoutProps>> = ({
  orgInfo,
  withoutLogo,
  onBack,
  children,
}) => {
  const imgOrgDefault =
    'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/517c3074-b633-4524-b939-f4318f7652c9-frame-627196.png?auto=format&fit=clip&w=529&h=580&q=100';
  const imgOrgInfo = `${orgInfo.loginImage}?w=564&h=580&auto=format&fit=contain`;
  const [imgOrg, setImgOrg] = useState(imgOrgDefault);
  const [imgLoading, setImgLoading] = useState(true);

  useEffect(() => {
    verifyURLImage(imgOrgInfo).then(result => {
      if (result.loading) {
        setImgLoading(true);
      } else if (result.success) {
        setImgOrg(imgOrgInfo);
        setImgLoading(false);
      } else {
        setImgOrg(imgOrgDefault);
        setImgLoading(false);
      }
    });
  }, []);

  return (
    <div className="relative w-full h-full bg-white">
      <GlobalStyles
        primaryColor={orgInfo.lookAndFeel?.primary?.main || undefined}
      />

      <div className="relative flex flex-wrap items-start justify-center w-full min-h-screen pt-32 sm:pt-64 pb-20">
        <section className="cui-container">
          <section className="tw-container tw-grid-cols-1 sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 w-full max-w-full">
            <div className="hidden md:flex lg:flex flex-col justify-start">
              <div
                className="flex justify-start h-full"
                css={{ maxWidth: 580 }}
              >
                {imgLoading ? (
                  <Skeleton
                    css={{ width: 529, height: 580 }}
                    isAnimated={true}
                    className="tw-rounded-1"
                  />
                ) : (
                  <Image
                    className="tw-rounded-1"
                    src={imgOrg}
                    alt="default_login_image"
                    width={529}
                    height={580}
                    objectFit="contain"
                  />
                )}
              </div>
              <PoweredBy />
            </div>
            <div className="p-8">
              <div className="relative">
                {onBack && (
                  <IconButton
                    className="absolute left-0 -ml-72 hidden sm:block"
                    arrow="left"
                    onClick={onBack}
                  />
                )}
                <div className="mb-56 md:m-0">
                  {!withoutLogo && (
                    <div className="flex items-center justify-center md:block mt-20 mb-32 sm:mb-56 md:mt-0 md:mb-16">
                      {orgInfo.logoThumbnail ? (
                        <img
                          className="h-40"
                          alt={orgInfo.name}
                          src={getImgxUrl(orgInfo.logoThumbnail, {
                            auto: 'format',
                            h: 56,
                            w: 'auto',
                            dpr: 2,
                          })}
                        />
                      ) : (
                        <CrehanaLogoIso
                          size={18}
                          ignoreColor
                          className="fill-current text-primary-main"
                        />
                      )}
                    </div>
                  )}

                  <div className="bg-white shadow-2-dp md:shadow-none md:bg-transparent py-36 px-24 sm:px-56 md:px-0 md:py-0 rounded-10">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default B2BOrgLayout;
