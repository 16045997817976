import { FC } from 'react';

import { clsx } from 'clsx';

import { useTranslation } from '@crehana/i18n';
import { PrimaryButton } from '@crehana/ui';

import { ErrorOverlayProps } from './types';

const ErrorOverlay: FC<React.PropsWithChildren<ErrorOverlayProps>> = ({
  isDark,
  onTryAgain,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'w-full min-h-screen flex flex-col justify-center items-center',
        isDark ? 'bg-base-dark text-white' : 'bg-gray-lighter text-base-main',
      )}
    >
      <h3 className="font-h3 mb-24">{t('OVERLAY_ERROR_TITLE')}</h3>
      {onTryAgain && (
        <PrimaryButton
          label={t('OVERLAY_ERROR_BUTTON_TRY_AGAIN_LABEL')}
          onClick={onTryAgain}
        />
      )}
    </div>
  );
};

export default ErrorOverlay;
