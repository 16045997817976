import { FC } from 'react';

import { CustomNextImage as Image } from '@crehana/next';
import { Col, Container, IconButton, Row } from '@crehana/ui';

import { CrehanaLogos } from './components';
import { B2BLayoutProps } from './types';

const B2BLayout: FC<React.PropsWithChildren<B2BLayoutProps>> = ({
  onBack,
  children,
}) => (
  <div className="relative text-white w-full h-full">
    <div className="w-1/2 h-full absolute hidden md:block">
      {/* TODO: talk with the code owner to fix this code so we can enable this rule again */}
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image
        src={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-a7660c1b-7369-4b22-95af-a4cc45aebaf8-bg-desktop.png?auto=format'
        }
        layout="fill"
        objectPosition="left"
        objectFit="contain"
        placeholder="blur"
        blurDataURL={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-a7660c1b-7369-4b22-95af-a4cc45aebaf8-bg-desktop.png?auto=format&blur=200&px=24'
        }
      />
    </div>
    <div className="absolute w-full h-full">
      {/* TODO: talk with the code owner to fix this code so we can enable this rule again */}
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <Image
        src={
          'https://crehana-vercel-images-prd.imgix.net/public/crehana-frontend-mf/automated-v2-2f382dd8-0e8b-46c7-aebc-883ebde6f369-bg-gradient.png?auto=format'
        }
        layout="fill"
        objectPosition="center"
        objectFit="cover"
      />
    </div>
    <div className="relative flex flex-wrap items-center justify-center w-full min-h-screen py-20">
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <div className="mb-24 flex justify-center md:items-center h-full md:hidden lg:hidden">
              <CrehanaLogos />
            </div>
          </Col>
          <Col
            xs={12}
            sm={8}
            md={6}
            lg={5}
            offset={{
              sm: 2,
              md: 0,
              lg: 0,
            }}
          >
            <div className="relative">
              {onBack && (
                <IconButton
                  className="absolute left-0 -ml-72 hidden sm:block"
                  arrow="left"
                  onClick={onBack}
                />
              )}
              <div className="rounded-10 bg-white px-20 py-24 lg:px-48 lg:py-48 shadow-dropdown">
                {children}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </div>
);

export default B2BLayout;
