// @ts-nocheck
/**
 * Matches emails
 */
export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
 * Matches MX dni
 */
export const REGEX_CURP =
  /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{0,1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)?[B-DF-HJ-NP-TV-Z]{0,3}[0-9A-Z]{0,1}[0-9]{0,1}$/;

/*
 * Matches Peru dni
 */
export const REGEX_DNI_PERU = /[0-9]{8,9}/;

/**
 * Accepts non numeric characters and spaces (case insensitive)
 */
export const CHARACTERS_ONLY = /^[A-Za-z\s]+$/;

/**
 * Accepts numbers only
 */
export const NUMBERS_ONLY = /^[0-9]+$/;

/**
 * Matches $3,023,123.34 | 9,876,453 | 123456.78
 */
export const MONEY =
  /^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(\.[0-9][0-9])?$/;

/**
 * Matches:
 * - dd/mm/yyyy
 * - dd-mm-yyyy
 * - dd.mm.yyyy
 */
export const DATE =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{4})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)\d{2})$/;
