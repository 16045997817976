import styled from 'styled-components';

import { neutralLight } from '@crehana/tailwindcss/colors';

export const Container = styled.section`
  display: flex;
  background-color: transparent;
  padding: 16px 0px;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    background-color: ${neutralLight['200']};
    margin-bottom: 0px;
  }
`;
