const routes = {
  // B2C routes
  homeUser: '/home',
  loginPath: '/entrar/',
  registerPath: '/registro/',
  legalPath: '/legal/',
  recoverPasswordPath: '/entrar/olvidaste-contrasena/',

  // B2B routes
  b2bLoginPath: '/business/entrar/',
  b2bActivePath: '/business/entrar/activar/',
  b2bRecoverPasswordPath: '/business/entrar/olvidaste-contrasena/',

  // B2B org routes
  b2bOrgHomeUser: (slug: string) => `/org/${slug}/learn/`,
  b2bOrgLoginPath: (slug: string) => `/org/${slug}/entrar/`,
  b2bOrgActivePath: (slug: string) => `/org/${slug}/entrar/activar/`,
  b2bOrgChangePasswordPath: (slug: string) =>
    `/org/${slug}/change-password-b2b/`,
  b2bOrgRecoverPasswordPath: (slug: string) =>
    `/org/${slug}/olvidaste-contrasena/`,

  catalog: '/cursos-online/',
};

export default routes;
