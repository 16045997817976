import CrehanaLogoIso from '@crehana/icons/CrehanaLogoIso';
import { white } from '@crehana/ui/dist/styles/colors';

const CrehanaLogos = () => (
  <>
    <CrehanaLogoIso className="sm:hidden" color={white} size={20} />
    <CrehanaLogoIso
      className="hidden sm:block lg:hidden"
      color={white}
      size={28}
    />
    <CrehanaLogoIso className="hidden lg:block" color={white} size={34} />
  </>
);

export default CrehanaLogos;
