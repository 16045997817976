import { LogoLight } from './Logos';
import * as St from './PoweredBy.styles';

const PoweredBy = () => {
  return (
    <St.Container className="flex items-center">
      <span className="tw-font-body5 tw-text-neutral-light-500 mr-4">
        Powered by
      </span>
      <div className="ml-2">
        <LogoLight />
      </div>
    </St.Container>
  );
};

export default PoweredBy;
