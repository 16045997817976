import { FC } from 'react';

import { clsx } from 'clsx';

import { Loader } from '@crehana/ui';

import { LoadingOverlayProps } from './types';

const LoadingOverlay: FC<React.PropsWithChildren<LoadingOverlayProps>> = ({
  isDark,
}) => (
  <div
    className={clsx(
      'w-full min-h-screen flex flex-col justify-center items-center',
      isDark ? 'bg-base-dark text-white' : 'bg-gray-lighter text-base-main',
    )}
  >
    <Loader size={48} white={isDark} />
  </div>
);

export default LoadingOverlay;
