import { FC } from 'react';

import { clsx } from 'clsx';

import SocialButtons from '@/shared/components/AuthForm/components/SocialButtons';

import { B2CLoginSocialButtonsProps } from './types';

const B2CLoginSocialButtons: FC<
  React.PropsWithChildren<B2CLoginSocialButtonsProps>
> = ({ className, ...rest }) => (
  <div className={clsx(className, 'flex justify-center')}>
    <SocialButtons {...rest} variant="minimal-2" />
  </div>
);

export default B2CLoginSocialButtons;
