import { NextSeo } from 'next-seo';

import { useTranslation } from '@crehana/i18n';

const AuthNextSeo = () => {
  const { t } = useTranslation();

  return (
    <NextSeo
      title={t('SEO_TITLE')}
      titleTemplate="%s | Crehana"
      description={t('SEO_DESCRIPTION')}
      openGraph={{
        type: 'website',
        images: [
          {
            url: 'https://static.crehana.com/static/img/logo/logo-crehana.6a48047513e0.png',
            alt: 'Crehana',
          },
        ],
      }}
    />
  );
};

export default AuthNextSeo;
