import { FC, Fragment } from 'react';

import { CrehanaTFunction, useTranslation } from '@crehana/i18n';
import { primaryLight } from '@crehana/tailwindcss/colors';
import { Checkbox } from '@crehana/ui';

import { LegalCheckboxProps } from './types';

export const rulesForRHFControllerComponent = (t: CrehanaTFunction) => ({
  required: {
    value: true,
    message: t(
      'AUTH_VALIDATION_LEGALS_REQUIRED',
      'Debes aceptar los Términos, Condiciones y Políticas para continuar.',
    ),
  },
});

export const LegalErrorMessage: FC<
  React.PropsWithChildren<{ message?: string }>
> = ({ message }) => (
  <span className="font-legals text-red-main block mt-4" role="alert">
    {message}
  </span>
);

const LegalCheckbox: FC<React.PropsWithChildren<LegalCheckboxProps>> = ({
  onChange,
  value,
  darkMode,
  dataTestId,
}) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      small
      darkMode={darkMode}
      data-testid={dataTestId}
      label={
        <Fragment>
          <span className="tw-font-subtitle5">
            {t('LEGALS_TEXT_1', 'Acepto los ')}
          </span>
          <a
            href="/legal/"
            target="_blank"
            className="tw-font-subtitle5 tw-text-primary-light-500"
            style={{
              textDecoration: 'none',
            }}
          >
            {t(
              'LEGALS_TEXT_2',
              'Términos, Condiciones y Políticas de Crehana.',
            )}
          </a>
        </Fragment>
      }
      onChange={event => {
        const { checked } = event.target;
        onChange(!checked);
      }}
      checked={value}
      css={`
        .bg-secondary-main {
          background-color: ${primaryLight[500]};
        }
      `}
    />
  );
};

export default LegalCheckbox;
