import { ImageLoaderProps } from 'next/legacy/image';

export function normalizeSrc(src: string): string {
  return src[0] === '/' ? src.slice(1) : src;
}

// Reference: https://github.com/vercel/next.js/blob/canary/packages/next/client/image.tsx#L960
export function imgixLoader({ src, width, quality }: ImageLoaderProps): string {
  // Demo: https://static.imgix.net/daisy.png?auto=format&fit=max&w=300
  const url = new URL(normalizeSrc(src));

  const params = url.searchParams;

  params.set('auto', params.get('auto') || 'format');
  params.set('fit', params.get('fit') || 'max');
  params.set('w', params.get('w') || width.toString());

  if (quality) {
    params.set('q', quality.toString());
  }

  return url.href;
}

export function verifyURLImage(url: string) {
  return fetch(url, { method: 'HEAD' })
    .then(response => {
      if (response.ok) {
        return { loading: false, success: true };
      }
      return { loading: false, success: false };
    })
    .catch(error => {
      console.error('Error al verificar la URL de la imagen:', error);
      return { loading: false, success: false };
    });
}
